import styled from 'styled-components'

export default styled.div`
  position: relative;
  width: 200px;
  .text-input-container {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid white;
    &.focus,
    &.error {
      border-bottom: 1px solid #981214;
    }
    &.disabled {
      border-bottom: 1px solid #3d3d3d;
    }
    .input-component {
      flex: 1;
      width: 100%;
      font-size: 18px;
      font-family: 'Prompt', sans-serif;
      border: none;
      outline: none;
      background: inherit;
      color: white;
      &:disabled {
        color: #4a4a4a;
        ::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #4a4a4a;
        }
        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #4a4a4a;
        }
      }
      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        font-family: 'Prompt', sans-serif;
        color: #8a8a8a;
        opacity: 1; /* Firefox */
      }
      ::-ms-input-placeholder {
        /* Microsoft Edge */
        font-family: 'Prompt', sans-serif;
        color: #8a8a8a;
      }
    }
    .password-icon {
      display: flex;
      width: 24px;
      height: 24px;
      font-size: 24px;
      cursor: pointer;
      color: white;
      &.disabled {
        color: #4a4a4a;
        pointer-events: none;
      }
    }
  }
  .error-text-container {
    position: absolute;
    bottom: -18px;
    left: 0px;
    width: 100%;
    min-height: 18px;
    font-size: 12px;
    color: #eb3e42;
  }
`
