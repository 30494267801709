import React from 'react'
import PropTypes from 'prop-types'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'

import Label from '../Label/dynamic'

import TextInputStyled from './styled'

class TextInput extends React.PureComponent {
  state = {
    isFocus: false,
    canSeePassword: false,
  }

  getLabel() {
    let output = null
    if (this.props.label) {
      output = <Label id={`${this.props.id}-label`} text={this.props.label} error={!!this.props.errorText} disabled={this.props.disabled} />
    }
    return output
  }

  handleInputChanged = e => {
    if (this.props.onChange) {
      this.props.onChange(e.target.value)
    }
  }

  handleFocusInput = () => {
    this.setState({
      isFocus: true,
    })
    if (this.props.onFocus) this.props.onFocus()
  }

  handleBlurInput = () => {
    this.setState({
      isFocus: false,
    })
    if (this.props.onBlur) this.props.onBlur()
  }

  togglePassword = () => {
    this.setState({
      canSeePassword: !this.state.canSeePassword,
    })
  }

  getInputIcon() {
    let inputIcon = null
    if (this.props.inputIcon) {
      inputIcon = <img src={this.props.inputIcon} className="input-icon" />
    }
    return inputIcon
  }

  getPasswordIcon() {
    let output = null
    if (this.props.type === 'password') {
      let className = 'password-icon'
      className += this.props.disabled ? ' disabled' : ''
      output = (
        <div id={`${this.props.id}-password-icon`} data-test={`${this.props.id}-password-icon`} className={className} onClick={this.togglePassword}>
          {this.state.canSeePassword ? <MdVisibility /> : <MdVisibilityOff />}
        </div>
      )
    }
    return output
  }

  getInput() {
    let className = 'text-input-container'
    className += this.state.isFocus ? ' focus' : ''
    className += this.props.errorText ? ' error' : ''
    className += this.props.disabled ? ' disabled' : ''
    return (
      <div id={`${this.props.id}-input-wrapper`} data-test={`${this.props.id}-input-wrapper`} className={className}>
        <input
          id={this.props.id}
          data-test={this.props.id}
          className="input-component"
          type={this.state.canSeePassword ? 'text' : this.props.type}
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.handleInputChanged}
          onFocus={this.handleFocusInput}
          onBlur={this.handleBlurInput}
          disabled={this.props.disabled}
        />
        {this.getPasswordIcon()}
        {this.getInputIcon()}
      </div>
    )
  }

  getError() {
    return (
      <div id={`${this.props.id}-error-txt`} data-test={`${this.props.id}-error-txt`} className="error-text-container">
        {this.props.errorText}
      </div>
    )
  }

  render() {
    return (
      <TextInputStyled id={`${this.props.id}-wrapper`} data-test={`${this.props.id}-wrapper`} className={this.props.className}>
        {this.getLabel()}
        {this.getInput()}
        {this.getError()}
      </TextInputStyled>
    )
  }
}

TextInput.defaultProps = {
  id: 'text-input',
  type: 'text',
  className: '',
  label: '',
  placeholder: '',
  value: '',
  errorText: '',
  disabled: false,
}

TextInput.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  inputIcon: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

export default TextInput
